import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { UsuarioService } from './usuario.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService {

  constructor(private authService: UsuarioService,private router: Router) { }

  canActivate(): boolean{
    let value = this.authService.isAuthenticated();
    //console.log("valor de login: " +value)
    if (!value) {
      this.router.navigateByUrl("/inicio");
    }
    //console.log('verificado')
    return value;
    //console.log(this.authService.isAuthenticated());
    //return this.authService.isAuthenticated();
  }

  
}
