import { Component, OnInit } from '@angular/core';
import { PedidoService } from 'src/app/services/pedido.service';
import { SharingService } from 'src/app/services/sharing.service';

@Component({
  selector: 'app-head-puntos',
  templateUrl: './head-puntos.component.html',
  styleUrls: ['./head-puntos.component.scss'],
})
export class HeadPuntosComponent implements OnInit {

  saldo: number = 0;
  quetzalesEnPuntos: number = 0;
  saldoAcumulado: number = 0;

  constructor( private sharingService: SharingService,
    private pedidoService: PedidoService) { }

  ngOnInit() {

    this.sharingService.sharingSaldoObservable.subscribe((_saldo)=>{
      console.log("LLEGO NUEVO VALOR DE SALDO OBSERVABLE");
      if(_saldo != null)
      {
        this.saldo = _saldo;
        this.saldoAcumulado = parseInt(localStorage.getItem("saldoAcumulado"));

      }
      else
      {
        //adquirir de localstorage
        this.saldo = parseInt(localStorage.getItem("saldo"));
        this.saldoAcumulado =  parseInt(localStorage.getItem("saldoAcumulado"));
        console.log("SALDO ACUMULADO: " + parseInt(localStorage.getItem("saldoAcumulado")));
      }
      console.log(this.saldo);
      this.calcularQuetzales();
      
    });

  

  }

  ionViewDidEnter()
  {
 
  }


  calcularQuetzales()
  {
    //obtener tasa de conversion de backend X puntos son a Y quetzales
    this.pedidoService.getTasa().subscribe(data =>
      {
        console.log("TASA: ");
        console.log(data.tasa);
        let tasaConversion: number = data.tasa; //10 puntos son 1 quetzales
        this.quetzalesEnPuntos = this.saldo / tasaConversion;
        return this.quetzalesEnPuntos;

      });


  }

  

}
