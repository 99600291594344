export class Persona {

    constructor(
        public personaId: string,
        public nombre1: string,
        public nombre2: string,
        public apellido1: string,
        public apellido2: string,
        public email: string,
        public telefono: string,
        public fechaNacimiento: string,
        public nit: string,
        public rutaImagen: string,
        public razonSocial: string,
        public direccion: string
    ) {}

    
}
