import { Component, OnInit } from '@angular/core';
import { ItemReorderEventDetail, ModalController, ToastController } from '@ionic/angular';
import { PaqueteVideojuego } from 'src/app/models/paquete-videojuego';
import { LoadingService } from 'src/app/services/loading.service';
import { PedidoService } from 'src/app/services/pedido.service';
import { VideoJuegoService } from 'src/app/services/video-juego.service';

@Component({
  selector: 'app-paquetes-videojuego',
  templateUrl: './paquetes-videojuego.component.html',
  styleUrls: ['./paquetes-videojuego.component.scss'],
})
export class PaquetesVideojuegoComponent implements OnInit {

  agregarPaquete: boolean = false;
  listPaquetes: PaqueteVideojuego[] = [];
  juegoObj: any;
  paqueteObj = new PaqueteVideojuego("",0);
  public isDisabledOrder = true;
  tasaConversion: number = 0;

  constructor(   private modalCtrl: ModalController,
    private loading: LoadingService,
    private juegoService: VideoJuegoService,
    private toastCtrl: ToastController,
    private pedidoService: PedidoService) { }

  ngOnInit() 
  {
    this.tasa();
  }

  tasa()
  {
    //obtener tasa de conversion de backend X puntos son a Y quetzales
    this.pedidoService.getTasa().subscribe(data =>
      {
        console.log("TASA: ");
        console.log(data.tasa);
        this.tasaConversion = data.tasa; //10 puntos son 1 quetzales

      });


  }

  ionViewDidEnter()
  {

    console.log("VIDEOJUEGO DE BDD");

    if(this.juegoObj.jsonVideoJuego != null)
    this.listPaquetes = JSON.parse(this.juegoObj.jsonVideoJuego);
  }


  public toggleAgregar(): void {
    this.agregarPaquete = !this.agregarPaquete;
 }

 agregar()
 {
  console.log("LLEGO AGREGAR");
  console.log(this.paqueteObj);
  if(this.paqueteObj.precio != 0)
  {
    let nuevoPaquete: PaqueteVideojuego = new PaqueteVideojuego(this.paqueteObj.nombre, this.paqueteObj.precio);
    this.listPaquetes.push(nuevoPaquete);
    this.putPaquetes();
  }
  else
  {
    this.presentToast("Por favor coloca precio al paquete.");
  }

 }

 eliminar(itemPaquete: PaqueteVideojuego)
 {
  let i = this.listPaquetes.indexOf(itemPaquete);
  this.listPaquetes.splice(i,1);
  this.putPaquetes();
 }

 handleReorder(ev: CustomEvent<ItemReorderEventDetail>) {
  // Before complete is called with the items they will remain in the
  // order before the drag
  console.log('Before complete', this.listPaquetes);

  // Finish the reorder and position the item in the DOM based on
  // where the gesture ended. Update the items variable to the
  // new order of items
  this.listPaquetes = ev.detail.complete(this.listPaquetes);

  // After complete is called the items will be in the new order
  console.log('After complete', this.listPaquetes);
  this.putPaquetes();
}

 salirModal()
 {
   this.modalCtrl.dismiss();
 }

 toggleReorder() {
  console.log("llego reorder");
  this.isDisabledOrder = !this.isDisabledOrder;
}

 putPaquetes()
 {
  this.loading.present();
  console.log(this.juegoObj);
  this.juegoObj.jsonVideoJuego = JSON.stringify(this.listPaquetes);
  this.juegoService.putVideoJuego(this.juegoObj).subscribe( data => 
    {
      this.loading.dismiss();
      //this.mandarImagen(data.pago);
      this.presentToast("Paquetes agregados o editados a videojuego");
    
    },
    error =>
    {
      this.loading.dismiss();
      this.presentToast("Los paquetes no se han creado correctamente, comunícate con nosotros para apoyarte al respecto.");
    });
 
 }

 async presentToast(mensaje: string) {
  const toast = await this.toastCtrl.create({
    message: mensaje,
    duration: 2000
  });
  toast.present();
}



}
