import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { VideoJuego } from '../models/video-juego';
import { Respuesta } from '../models/respuesta';
import { CategoriaVideojuego } from '../models/categoria-videojuego';

@Injectable({
  providedIn: 'root'
})
export class VideoJuegoService {


  baseUrl = 'https://apigolden.digitalrising.com.gt'//'https://apigolden.digitalrising.com.gt'; //https://apigoldenplay.digitalrising.com.gt
  constructor(private http: HttpClient) { }

  getVideoJuegos(){

    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'usuario': localStorage.getItem("personaId")
      });

    return this.http.get<VideoJuego>(this.baseUrl + "/VideoJuego", {headers: headers});
  }

  getVideoJuegosxCategoria(categoria : number){

    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'usuario': localStorage.getItem("personaId")
      });

    return this.http.get<VideoJuego[]>(this.baseUrl + `/VideoJuego/Categoria/${categoria}`, {headers: headers});
  }

  getVideoJuegosPublic(){

    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'usuario': '27' //COLOCAR AQUI UN ID QUE NUNCA SE VAYA A ELIMINAR "INVITADOS"
      });

    return this.http.get<VideoJuego>(this.baseUrl + "/VideoJuego", {headers: headers});
  }

  getVideoJuegosArray(){

    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'usuario': localStorage.getItem("personaId")
      });

    return this.http.get<VideoJuego[]>(this.baseUrl + "/VideoJuego", {headers: headers});
  }

  getCategorias(){

    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json'
      });

    return this.http.get<CategoriaVideojuego>(this.baseUrl + "/Categoria", {headers: headers});
  }

  getCategoriasEdit() {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json'
      });

    return this.http.get<CategoriaVideojuego[]>(this.baseUrl + "/Categoria", {headers: headers});
  } 

  postVideoJuego(juegoObj: VideoJuego)
  {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'usuario': localStorage.getItem("personaId")
      });

    //  console.log(pagoObj);
    console.log("USUARIO:");
    console.log(localStorage.getItem("personaId"));
    return this.http.post<Respuesta>(this.baseUrl + "/VideoJuego", juegoObj, { headers: headers });
  }

  putVideoJuego(juegoObj: VideoJuego)
  {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'usuario': localStorage.getItem("personaId")
      });

    //  console.log(pagoObj);
    console.log(localStorage.getItem("personaId"));
    return this.http.put<Respuesta>(this.baseUrl + "/VideoJuego", juegoObj, { headers: headers });
  }

  putVideoJuegoOrden(juegoObj: VideoJuego)
  {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'usuario': localStorage.getItem("personaId")
      });

    console.log(juegoObj);
    console.log(localStorage.getItem("personaId"));
    return this.http.put<Respuesta>(this.baseUrl + "/VideoJuego/Orden", juegoObj, { headers: headers });
  }

  putVideoJuegoOrdenCategoria(juegoObj: VideoJuego, categoria:number)
  {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'usuario': localStorage.getItem("personaId")
      });

    console.log(juegoObj);
    console.log(localStorage.getItem("personaId"));
    return this.http.put<Respuesta>(this.baseUrl + `/VideoJuego/OrdenCat/${categoria}`, juegoObj, { headers: headers });
  }

  deleteVideoJuego(juegoObj: VideoJuego)
  {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'usuario': localStorage.getItem("personaId")
      });

    return this.http.delete<Respuesta>(this.baseUrl + "/VideoJuego/" + juegoObj , { headers: headers });
    
  }
}
