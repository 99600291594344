import { Component, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-escaner-qr',
  templateUrl: './escaner-qr.component.html',
  styleUrls: ['./escaner-qr.component.scss'],
})
export class EscanerQRComponent implements OnInit {

  escaneoExito: number = 0;
  objeto : any;

  constructor(private popCtrl: PopoverController) { }

  ngOnInit() {}


  escaneoWeb(resultado)
  {
    
    //validar que solo pueda entrar una vez
    if(this.escaneoExito != 1)
    {
      this.escaneoExito = 1;
      this.popCtrl.dismiss(resultado);

    }
  }

}
