import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { PaqueteVideojuego } from '../models/paquete-videojuego';
import { VideoJuego } from '../models/video-juego';

@Injectable({
  providedIn: 'root'
})
export class SharingService {

  private sharingObject: any;
  private sharingJuegoObj: VideoJuego = null;
  private sharingUsuarioId: any = null;
  private sharingPaquetObj: PaqueteVideojuego = null;
  private sharingPedidoUsuario: any = null;
  sharingSaldoObservable = new BehaviorSubject(null);
  sharingRolObservable = new BehaviorSubject(null);

  constructor() { }


  get sharingValue() {
    return this.sharingObject
  }

  set sharingValue(obj) {
    this.sharingObject = obj;
  }

  get sharingJuego() {
    return this.sharingJuegoObj;
  }

  set sharingJuego(obj) {
    this.sharingJuegoObj = obj;
  }

  get sharingUsuario() {
    return this.sharingUsuarioId;
  }

  set sharingUsuario(obj) {
    this.sharingUsuarioId = obj;
  }

  get sharingPaquete() {
    return this.sharingPaquetObj;
  }

  set sharingPaquete(obj) {
    this.sharingPaquetObj = obj;
  }

  get sharingPedido() {
    return this.sharingPedidoUsuario;
  }

  set sharingPedido(obj) {
    this.sharingPedidoUsuario = obj;
  }

  

  
 setSaldoObservable(saldo){
 
  this.sharingSaldoObservable.next(saldo);

}

setRolObservable(rol){
 
  this.sharingRolObservable.next(rol);

}


}
