import { Component, OnInit } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';
import { SharingService } from 'src/app/services/sharing.service';

@Component({
  selector: 'app-generador-qr',
  templateUrl: './generador-qr.component.html',
  styleUrls: ['./generador-qr.component.scss'],
})
export class GeneradorQRComponent implements OnInit {


  public myAngularxQrCode: string = null;
  public qrCodeDownloadLink: SafeUrl = "";
  objeto : any;
  isNative: Boolean;
  
  constructor(    private sharingService: SharingService) { 
    console.log("LLEGO CONSTRUCTOR");
    console.log(this.sharingService.sharingValue);
    this.myAngularxQrCode =  this.sharingService.sharingValue;
  }

  ngOnInit() {

    if(localStorage.getItem("isNative")=="false"){
      this.isNative=false;
    }else{
      this.isNative=true;
    }

    console.log("LLEGO ngOnInit");
    console.log(this.objeto);
    this.myAngularxQrCode =  this.sharingService.sharingValue;
  }

  ionViewDidEnter()
  {
    console.log("LLEGO ionViewDidEnter");
    console.log(this.objeto);
    this.myAngularxQrCode =  this.sharingService.sharingValue;
    
  }

  onChangeURL(url: SafeUrl) {
    this.qrCodeDownloadLink = url;
  }


}
