import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';

import { IonicStorageModule } from '@ionic/storage';
import { ComponentsModule } from './components/components.module';

import { FiltroPipe } from './pipes/filtro.pipe';
import { PipesModule } from './pipes/pipes.module';
import { PopPedidosComponent } from './components/pop-pedidos/pop-pedidos.component';
import {BarcodeScanner} from '@ionic-native/barcode-scanner/ngx';
import { File } from '@ionic-native/file/ngx';
import { PhotoLibrary } from '@awesome-cordova-plugins/photo-library/ngx';



@NgModule({
  declarations: [AppComponent],
  entryComponents: [PopPedidosComponent],
  imports: [BrowserModule, IonicModule.forRoot(), AppRoutingModule,
     HttpClientModule, IonicStorageModule.forRoot(), ComponentsModule,
    PipesModule],
  providers: [BarcodeScanner, File, PhotoLibrary,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }],
  bootstrap: [AppComponent],
})
export class AppModule {}
