import { Component, OnInit } from '@angular/core';
import { PopoverController, ToastController, ActionSheetController, AlertController } from '@ionic/angular';
import { Router } from '@angular/router';
import { UsuarioService } from '../../services/usuario.service';
import { SharingService } from 'src/app/services/sharing.service';
import { VideoJuego } from 'src/app/models/video-juego';
import { Usuarios } from 'src/app/models/usuarios';
import { Persona } from 'src/app/models/persona';

@Component({
  selector: 'app-pop-opciones-usuarios',
  templateUrl: './pop-opciones-usuarios.component.html',
  styleUrls: ['./pop-opciones-usuarios.component.scss'],
})
export class PopOpcionesUsuariosComponent implements OnInit {

 /* Variables para verificacion de plataforma nativa */
 isNative : Boolean;
 objeto : any;

 juegoObj = new VideoJuego(0,"","",0,"");
 personaObj = new Persona("0","","","","","","","","",
 "","","");
 usuarioObj = new Usuarios("", this.personaObj, "", 0,"","",0,"","","","","",
 "","","",0,0);

 constructor(
             private popCtrl: PopoverController,
             private toastCtrl: ToastController,
             private router: Router,
             public actionSheetController: ActionSheetController,
             private usuarioService: UsuarioService,
             private alertController: AlertController,
             private sharingService: SharingService) { }

 ngOnInit() {
   /* Verifica si la plataforma es nativa */
   if(localStorage.getItem("isNative")=="false"){
     this.isNative=false;
   }else{
     this.isNative=true;
   }

 }


 async presentToast(mensaje: string) {
   const toast = await this.toastCtrl.create({
     message: mensaje,
     duration: 2000
   });
   toast.present();
 }


 perfil(usuario)
 {
   this.popCtrl.dismiss();
   this.router.navigate(['/editar-usuario/' + usuario.persona.personaId]);
 }

 
   
     editarUsuario(usuario){
       //console.log(usuario);
       this.usuarioService.putUsuario(usuario);
       this.presentToast('Has actualizado el estado del usuario');
     }


     
  async popSumarPuntos(opcion) {

    // let textoPop = opcion == 1 ? 'Ingresa el saldo que deseas agregar a ' + this.objeto.nombre : 'Ingresa el saldo que deseas restar a ' + this.objeto.nombre;
    // let textoBoton = opcion == 1 ? 'Agregar' : 'Restar';
    let textoPop = "";
    let textoBoton = "";

    if(opcion == 1)
      textoPop = 'Ingresa el saldo que deseas agregar a ' + this.objeto.nombre;
    else if(opcion == 2)
      textoPop = 'Ingresa el saldo que deseas restar a ' + this.objeto.nombre;
    else if(opcion == 3)
      textoPop = 'Ingresa el saldo que deseas restar a ' + this.objeto.nombre;
    else if(opcion == 4)
      textoPop = 'Ingresa el saldo que deseas agregar a ' + this.objeto.nombre;
    else if(opcion == 5)
      textoPop = 'Ingresa los puntos que deseas agregar a ' + this.objeto.nombre;


    const alert = await this.alertController.create({
      message: textoPop,
      inputs: [
        {
          name: 'monto',
          type: 'number',
          placeholder: 'monto',
          min: 1
        }
      ],
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          handler: data => {
            console.log('Cancelo');
          }
        },
        {
          text: 'Aplicar',
          handler: dataMonto => {
  
            console.log("ingreso suma/resta puntos");
            console.log(dataMonto);
            console.log("usuario");
            console.log(this.objeto.persona.personaId);
            
            if(opcion == 1){

              //SUMAR SALDO Y PUNTOS
            this.usuarioService.putSaldoUsr(parseInt(this.objeto.persona.personaId), parseInt(dataMonto.monto)).subscribe(data =>
              {
                console.log(data);
                this.presentToast('Has agregado Q' + dataMonto.monto.toString() + ' a ' +  this.objeto.nombre);
                this.actualizarSaldo();
                this.popCtrl.dismiss();
                
              });

            }
            else if(opcion == 2)
            {
              //RESTAR SALDO Y PUNTOS
              this.usuarioService.putSaldoUsrResta(parseInt(this.objeto.persona.personaId), parseInt(dataMonto.monto)).subscribe(data =>
                {
                  console.log(data);
                  this.presentToast('Has restado Q' + dataMonto.monto.toString() + ' y su equivalente en puntos a ' +  this.objeto.nombre);
                  this.actualizarSaldo();
                  this.popCtrl.dismiss();
                });

            }
            else if(opcion == 3)
            {
              //RESTAR SALDO
              this.usuarioService.putSaldoUsrResta_Saldo(parseInt(this.objeto.persona.personaId), parseInt(dataMonto.monto)).subscribe(data =>
                {
                  console.log(data);
                  this.presentToast('Has restado Q' + dataMonto.monto.toString() + ' a ' +  this.objeto.nombre);
                  this.actualizarSaldo();
                  this.popCtrl.dismiss();
                });

            }
            else if(opcion == 4)
            { //SUMAR SALDO
              this.usuarioService.putSaldoSinPuntos(parseInt(this.objeto.persona.personaId), parseInt(dataMonto.monto)).subscribe(data =>
                {
                  console.log(data);
                  this.presentToast('Has agregado Q' + dataMonto.monto.toString() + ' a ' +  this.objeto.nombre);
                  this.actualizarSaldo();
                  this.popCtrl.dismiss();
                  
                });
            }
            else if(opcion == 5)
            {
              //SUMAR PUNTOS
              this.usuarioService.putPuntosSinSaldo(parseInt(this.objeto.persona.personaId), parseInt(dataMonto.monto)).subscribe(data =>
                {
                  console.log(data);
                  this.presentToast('Has agregado ' + dataMonto.monto.toString() + ' puntos a ' +  this.objeto.nombre);
                  this.actualizarSaldo();
                  this.popCtrl.dismiss();
                  
                });

            }
        
            
     

          }
        }
      ]
    });
    await alert.present();
  }

  actualizarSaldo()
  {
      //OBTENER SALDO INICIAL
      this.usuarioService.getSaldoUsuario().subscribe(data =>
        {
          console.log("SALDO DE USUARIO");
          console.log(data);
          localStorage.setItem("saldo", data.saldo.toString());
          localStorage.setItem("saldoAcumulado", data.saldoAcumulado.toString());
          this.sharingService.setSaldoObservable(data.saldo);

        });
  }

  historial()
  {
    this.popCtrl.dismiss();
    this.sharingService.sharingPedido = this.objeto.nombre;
    localStorage.setItem("sharingPedido", this.objeto.nombre);
    this.router.navigate(['/pedidos']);
  }

  irCompra()
  {
    this.popCtrl.dismiss();
    this.sharingService.sharingUsuario = this.objeto;
    this.sharingService.sharingJuego = this.juegoObj;
    localStorage.setItem("sharingJuego", JSON.stringify(this.juegoObj));
    this.router.navigate(['/compra']);
  }
   
 

}
