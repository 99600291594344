import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filtro'
})
export class FiltroPipe implements PipeTransform {

  transform(arreglo: any[], texto: string, columna: string, columna2: string): any[] {

    if(arreglo){
     // console.log("texto-> "+texto)
     // console.log("columna-> "+columna)
     // console.log("columna2-> "+columna2)
      if(texto === ''){
        //console.log("return pipe vacio")
        return arreglo;
      }
      
      if(columna != 'categoriaVideojuego')
        texto = texto.toLowerCase();
      
      return arreglo.filter(item => {    
        if(columna2 != '' && columna2)
        {
       
          if((columna === 'cliente' || columna === 'persona') && columna2 != 'estado'){

            if(columna2 == 'email')
            {
              console.log("llego");
                let aux = item['persona']['email'];
                let auxNombre = item['nombre'];
                let aux1 = item['persona']['nombre1'];
              
                if(aux != null && aux.toLowerCase().includes(texto)){
                  console.log('contiene email!')
                  return item;
                }
                if(auxNombre != null && auxNombre.toLowerCase().includes(texto)){
                  console.log('contiene nombre!')
                  return item;
                }

                if(aux1 != null && aux1.toLowerCase().includes(texto)){
                  console.log('contiene nombre!')
                  return item;
                }
            }
            else
            {
                let aux = item[columna]['nombre1']+" "+item[columna]['apellido1']+" ";
                //console.log("aqui-> " + aux)
              
                if(item[columna]['nombre2'])
                  aux += item[columna]['nombre2'] + " ";
      
                if(item[columna]['apellido2'])
                  aux += item[columna]['apellido2'];
                
                if(aux.toLowerCase().includes(texto)){
                  //console.log('contiene!')
                  return item;
                }
            }
              
          }
          else if(columna === 'clientePOS' ){
           // console.log("ENTRO FILTRO POS");
            let aux = item['cliente']['nit'];
          
            
            if(aux.toLowerCase().replace('-', '').includes(texto.replace('-', ''))){
              //console.log('contiene!')
              return item;
            }
              
          }
          else if(columna === 'usuario')
          {
            console.log("llego columna usuario");
            console.log(item);
            if(item['usuarioCanje'] != null)
            {
              let aux = item['usuarioCanje']['nombre'];
          
              
              if(aux.toLowerCase().includes(texto)){
                //console.log('contiene!')
                return item;
              }
            }
            else
            {
              let aux = item['usuario']['nombre'];
          
              
              if(aux.toLowerCase().includes(texto)){
                //console.log('contiene!')
                return item;
              }
            }
          }
          else if(columna === 'usuariopedido')
          {
            console.log("llego columna usuario de pedidos");
            let aux = item['usuario'];
          
            
            if(aux.toLowerCase().includes(texto)){
              //console.log('contiene!')
              return item;
            }
          }
         
          else{
            //console.log("estado")
            //console.log("item-> " +JSON.stringify(item))
            let aux = item[columna2];
            //console.log(aux);
            //console.log("estado" + aux)
            if(aux == texto){
              //console.log("coincide")
              return item;
            }

          }
        }
        else
        {
         
          if(columna === 'genre'){
            
            // fechas de ion-datetime - inicio/fin
            let fechas = texto.split('|', 2);
            
            let fechas_inicio = fechas[0];
            let fechas_fin = fechas[1];

            let fecha_inicio = fechas_inicio.split("-", 3);
            let fecha_fin = fechas_fin.split("-", 3);

            //console.log('en select'+ fecha_inicio[0]+"-"+ fecha_inicio[1])
            //console.log('en select'+ fecha_fin[0]+"-"+ fecha_fin[1])

            let mes_inicio: number = +fecha_inicio[1];
            let año_inicio: number = +fecha_inicio[0];

            let mes_fin: number = +fecha_fin[1];
            let año_fin: number = +fecha_fin[0];

            // fecha del objeto
            let val = item[columna];
            let fecha = val.split("-", 3);

            //console.log('item - '+ fecha[0]+"-"+ fecha[1])

            var mes: number = +fecha[1];
            var año: number = +fecha[0];

            if(año_inicio <= año && mes_inicio <= mes){
              if(año <= año_fin && mes <= mes_fin){
                return item;
              }
            }

          }else if(columna =='json'){
            //console.log('Filtro establecimiento: '+ texto);
            let json = JSON.parse(item[columna]);
            let CodigoEstablecimiento = json.GTDocumento.SAT.DTE.DatosEmision.Emisor.CodigoEstablecimiento;
            //console.log(json.GTDocumento.SAT.DTE.DatosEmision.Emisor.CodigoEstablecimiento);
            if(CodigoEstablecimiento==texto){
              return item;
            }
            

          }
          else if(columna =='origenPago'){
            let aux = item[columna];
              if(aux == texto){
                return item;
              }
            

          }
          else if(columna == 'punto')
          {

            console.log("llego pipe punto de venta");
            if(item["nombre"].toLowerCase().includes(texto) || item["sku"].toLowerCase().includes(texto)){
              console.log("item encontrado: ", item);
              return item;
            }
          }
          else if(columna == 'nombreVideoJuego')
          {

            console.log("llego pipe nombre videojuego");
            if(item["nombre"].toLowerCase().includes(texto)){
              console.log("item encontrado: ", item);
              return item;
            }
          }
          else if(columna == 'categoriaVideojuego')
          {

            console.log("llego pipe categoria videojuego");
            if (texto === '0') {
              return item;
            } else {
              
              if (item["categoria"] == texto) {
                return item;
              }
            }
          }
          else if (columna === 'fechafiltroOrden') {

            console.log("llego columna fecha");
            if (texto === '' || !texto) {
              //console.log("return pipe vacio - NO se puede")
              return item;
            }
  
            let fechas = texto.split('|', 2);
            let fechas_inicio = fechas[0];
            let fechas_fin = fechas[1];
            console.log("fecha en bdd: " + item.fechaSinFormato);

            let date = new Date(item.fechaSinFormato);
            console.log("fecha: " + date);
            let date_inicio = new Date(fechas_inicio);
            console.log("date_inicio: " + date_inicio);
            date_inicio.setHours(0, 0, 0);
            date_inicio.setMinutes(0, 0, 0);
            date_inicio.setSeconds(0, 0);
            let date_fin = new Date(fechas_fin);
            console.log("date_fin: " + date_fin);
            date_fin.setHours(23, 0, 0);
            date_fin.setMinutes(59, 0, 0);
            date_fin.setSeconds(59, 0);
            console.log('texto: ' + date)
            console.log('fecha_inicio: ' + date_inicio)
            console.log('fecha_fin: ' + date_fin)
            if (date >= date_inicio && date <= date_fin) {
              console.log('encontrado ^')
              //console.log('texto: '+ date)
              //console.log('fecha_inicio: '+ date_inicio)
              //console.log('fecha_fin: '+ date_fin)
              //console.log("fecha " + JSON.stringify(item));
              return item;
            }
  
          }
          else if (columna === 'fechafiltroBoleta') {

            console.log("llego columna fecha boleta");
            if (texto === '' || !texto) {
              //console.log("return pipe vacio - NO se puede")
              return item;
            }
  
            let fechas = texto.split('|', 2);
            console.log("fechas IMPORTANTE: ");
            console.log(fechas);
            let fechas_inicio = fechas[0];
            let fechas_fin = fechas[1];
            console.log("fecha en bdd: " + item.fechaPago);

            let date = new Date(item.fechaPago);
            console.log("fecha: " + date);
            let date_inicio = new Date(fechas_inicio);
            console.log("date_inicio: " + date_inicio);
            date_inicio.setHours(0, 0, 0);
            date_inicio.setMinutes(0, 0, 0);
            date_inicio.setSeconds(0, 0);
            let date_fin = new Date(fechas_fin);
            console.log("date_fin: " + date_fin);
            date_fin.setHours(23, 0, 0);
            date_fin.setMinutes(59, 0, 0);
            date_fin.setSeconds(59, 0);
            console.log('texto: ' + date)
            console.log('fecha_inicio IMPORTANTE: ' + date_inicio)
            console.log('fecha_fin: ' + date_fin)
            if (date >= date_inicio && date <= date_fin) {
              console.log('encontrado ^')
              //console.log('texto: '+ date)
              //console.log('fecha_inicio: '+ date_inicio)
              //console.log('fecha_fin: '+ date_fin)
              //console.log("fecha " + JSON.stringify(item));
              return item;
            }
  
          }
          else{
            //console.log(columna);
            //console.log(texto);
            //console.log("entro 1 columna");
            //console.log(item);
            //2020-08
            // return item[columna].toLowerCase().includes(texto);
            if(columna != 'estado')
            {
              if(item[columna].toLowerCase().includes(texto)){
                //console.log('contiene fact!')
                return item;
              }else if(columna=='fecha') {
                //console.log("Hola mundo, si entro");
                let filtroFecha=(texto.split("t"))[0].split("-");

                //console.log(filtroFecha);
                //console.log(item[columna])
                let fechafactura=((item[columna]).toString().toLowerCase().split("t"))[0].split("-");
                //console.log(fechafactura);

                if(filtroFecha[0]=="2"){
                  if(fechafactura[2]>=filtroFecha[4] && filtroFecha[1]=="i"){
                    return item;
                  } 
                  if(fechafactura[2]<=filtroFecha[4] && filtroFecha[1]=="f"){
                    return item;
                  }

                }else if(filtroFecha[0]=="3"){
                  if(fechafactura[1]>=filtroFecha[3]&& filtroFecha[1]=="i"){
                    return item;
                  }
                  if(fechafactura[1]<=filtroFecha[3]&& filtroFecha[1]=="f"){
                    return item;
                  }
                }
              }
            }
            else
            {
              
              let aux = item[columna];
              //console.log(aux);
              //console.log("estado" + aux)
              if(aux == texto){
                //console.log("coincide estado fac");
                return item;
              }

            }


          }
        }
        

        
      })
    }
    return arreglo;
  }

}
