import { DetalleEstado } from "./detalle-estado";

export class Pedido {


    constructor(
        public pedidoId: number,
        public videoJuego: string,
        public usuario: string,
        public fecha: Date, 
        public estado: number,
        public detalleEstado: DetalleEstado,
        public cantidadPaquete?: number
    ) {}

}
