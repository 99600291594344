import { Component, OnInit } from '@angular/core';
import { ModalController, ToastController } from '@ionic/angular';
import { Observable } from 'rxjs';
import { CategoriaVideojuego } from 'src/app/models/categoria-videojuego';
import { Pago } from 'src/app/models/pago';
import { VideoJuego } from 'src/app/models/video-juego';
import { BoletaService } from 'src/app/services/boleta.service';
import { LoadingService } from 'src/app/services/loading.service';
import { VideoJuegoService } from 'src/app/services/video-juego.service';

@Component({
  selector: 'app-editar-juego',
  templateUrl: './editar-juego.component.html',
  styleUrls: ['./editar-juego.component.scss'],
})
export class EditarJuegoComponent implements OnInit {

  juegoObj: any;
  banco: string = "0";
  formData: FormData = new FormData(); 
  listadoCategorias: CategoriaVideojuego[];
  categoriaSeleccionada: string;
  

  constructor(private juegoService: VideoJuegoService, 
    private toastCtrl: ToastController,
    private loading: LoadingService,
    private modalCtrl: ModalController) { }

  ngOnInit() {
    this.getCategorias();
  }

  ionViewDidEnter()
  {
    console.log(this.juegoObj);
    this.getCategorias();
  }

  getCategorias() {
   this.juegoService.getCategoriasEdit().subscribe(data =>{
    this.listadoCategorias = data;
      this.categoriaSeleccionada = this.juegoObj.categoria.toString();
    });
   
  }

  editar()
  {

    this.loading.present();
    console.log(this.juegoObj);
    this.juegoObj.categoria = this.categoriaSeleccionada;
    this.juegoService.putVideoJuego(this.juegoObj).subscribe( data => 
      {
        this.loading.dismiss();
        this.mandarImagen(this.juegoObj.juegoId);
        this.presentToast("Videojuego editado");

      },
      error =>
      {
        this.loading.dismiss();
        this.presentToast("El videojuego no se ha creado correctamente, comunícate con nosotros para apoyarte al respecto.");
      });
   
  }

  

  async presentToast(mensaje: string) {
    const toast = await this.toastCtrl.create({
      message: mensaje,
      duration: 2000
    });
    toast.present();
  }

  salirModal()
  {
    this.modalCtrl.dismiss();
  }

  handleFileInput(files: FileList) {
    // console.log(files.item(0));
    this.formData = new FormData();
    this.formData.append('fileKey', files.item(0), files.item(0).name);
  }

  mandarImagen(videoJuegoId)
  {
    // console.log(pagoId);
    var request = new XMLHttpRequest();
    request.open("POST", "https://apigolden.digitalrising.com.gt/VideoJuego/upload/"+ videoJuegoId);
    request.onload =  () => {
      if (request.readyState === 4) {
        if (request.status === 200) {
          this.modalCtrl.dismiss();
        }
      }
      };
      request.send(this.formData);
      
      
  }

}
