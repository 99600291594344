export class VideoJuego {


    constructor(
        public juegoId: number,
        public nombre: string,
        public descripcion: string,
        public puntos: number,
        public urlImagen: string,
        public jsonVideoJuego?: string,
        public paquete?: string,
        public categoria?: number,
        public indiceOrdenamiento?: number,
        public linkMessenger?: string,
        public linkInstagram?: string


       
    ) {}


}
