import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from './services/auth-guard.service';

const routes: Routes = [

  {
    path: '',
    redirectTo: 'landing-public',
    pathMatch: 'full'
  },
  {
    path: 'inicio',
    loadChildren: () => import('./pages/inicio/inicio.module').then( m => m.InicioPageModule)
  },
  {
    path: 'landing',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./pages/landing/landing.module').then( m => m.LandingPageModule)
  },
  {
    path: 'registro/:id',
    loadChildren: () => import('./pages/registro/registro.module').then( m => m.RegistroPageModule)
  },
  {
    path: 'registro-exito',
    loadChildren: () => import('./pages/registro-exito/registro-exito.module').then( m => m.RegistroExitoPageModule)
  },
  {
    path: 'recuperar-contrasenia',
    loadChildren: () => import('./pages/recuperar-contrasenia/recuperar-contrasenia.module').then( m => m.RecuperarContraseniaPageModule)
  },
  {
    path: 'usuarios',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./pages/usuarios/usuarios.module').then( m => m.UsuariosPageModule)
  },
  {
    path: 'editar-usuario/:personaId',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./pages/editar-usuario/editar-usuario.module').then( m => m.EditarUsuarioPageModule)
  },
  {
    path: 'generar-qr',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./pages/generar-qr/generar-qr.module').then( m => m.GenerarQRPageModule)
  },
  {
    path: 'boletas',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./pages/boletas/boletas.module').then( m => m.BoletasPageModule)
  },
  {
    path: 'nueva-boleta',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./pages/nueva-boleta/nueva-boleta.module').then( m => m.NuevaBoletaPageModule)
  },
  {
    path: 'compra',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./pages/compra/compra.module').then( m => m.CompraPageModule)
  },
  {
    path: 'suma-puntos',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./pages/suma-puntos/suma-puntos.module').then( m => m.SumaPuntosPageModule)
  },
  {
    path: 'compra-exito',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./pages/compra-exito/compra-exito.module').then( m => m.CompraExitoPageModule)
  },
  {
    path: 'pedidos',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./pages/pedidos/pedidos.module').then( m => m.PedidosPageModule)
  },
  {
    path: 'perfil',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./pages/perfil/perfil.module').then( m => m.PerfilPageModule)
  },
  {
    path: 'nueva-contrasenia/:token',
    loadChildren: () => import('./pages/nueva-contrasenia/nueva-contrasenia.module').then( m => m.NuevaContraseniaPageModule)
  },
  {
    path: 'premios',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./pages/premios/premios.module').then( m => m.PremiosPageModule)
  },
  {
    path: 'landing-public',
    loadChildren: () => import('./pages/landing-public/landing-public.module').then( m => m.LandingPublicPageModule)
  },
  {
    path: 'puntos-admin',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./pages/puntos-admin/puntos-admin.module').then( m => m.PuntosAdminPageModule)
  },
  {
    path: 'historial-puntos',
    loadChildren: () => import('./pages/historial-puntos/historial-puntos.module').then( m => m.HistorialPuntosPageModule)
  },
  {
    path: 'codigos-referidos',
    loadChildren: () => import('./pages/codigos-referidos/codigos-referidos.module').then( m => m.CodigosReferidosPageModule)
  },
  {
    path: 'prueba',
    loadChildren: () => import('./pages/prueba/prueba.module').then( m => m.PruebaPageModule)
  },
  {
    path: 'pre-registro',
    loadChildren: () => import('./pages/pre-registro/pre-registro.module').then( m => m.PreRegistroPageModule)
  },  {
    path: 'historial-recomendacion',
    loadChildren: () => import('./pages/historial-recomendacion/historial-recomendacion.module').then( m => m.HistorialRecomendacionPageModule)
  },
  {
    path: 'generar-qr-puntos',
    loadChildren: () => import('./pages/generar-qr-puntos/generar-qr-puntos.module').then( m => m.GenerarQrPuntosPageModule)
  },







];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
