import { Component, OnInit } from '@angular/core';
import { ModalController, ToastController } from '@ionic/angular';
import { Observable } from 'rxjs';
import { CategoriaVideojuego } from 'src/app/models/categoria-videojuego';
import { Pago } from 'src/app/models/pago';
import { PaqueteVideojuego } from 'src/app/models/paquete-videojuego';
import { VideoJuego } from 'src/app/models/video-juego';
import { BoletaService } from 'src/app/services/boleta.service';
import { LoadingService } from 'src/app/services/loading.service';
import { VideoJuegoService } from 'src/app/services/video-juego.service';
import { PaquetesVideojuegoComponent } from '../paquetes-videojuego/paquetes-videojuego.component';

@Component({
  selector: 'app-nuevo-juego',
  templateUrl: './nuevo-juego.component.html',
  styleUrls: ['./nuevo-juego.component.scss'],
})
export class NuevoJuegoComponent implements OnInit {

  juegoObj = new VideoJuego(0,"","",0,"");

  banco: string = "0";
  formData: FormData = new FormData(); 
  agregarPaquete: boolean = false;
  listadoCategorias: Observable<CategoriaVideojuego>;

  constructor(private juegoService: VideoJuegoService, 
    private toastCtrl: ToastController,
    private loading: LoadingService,
    private modalCtrl: ModalController) { }

  ngOnInit() {
  }

  ionViewDidEnter()
  {
    this.getCategorias();
  }


  crear()
  {

    console.log(this.juegoObj);
    this.loading.present();
    this.juegoService.postVideoJuego(this.juegoObj).subscribe( data => 
      {
        console.log(data);
        this.loading.dismiss();
        this.mandarImagen(data.pago);
        this.presentToast("El nuevo videojuego aparecerá al final del catálogo, si deseas modificar su posición puedes presionar el icono de orden.");
        this.modalCtrl.dismiss();
        //asignar paquetes
        this.juegoObj.juegoId = data.pago;
        this.paquetes();

      },
      error =>
      {
        this.loading.dismiss();
        this.presentToast("El videojuego no se ha creado correctamente, comunícate con nosotros para apoyarte al respecto.");
      });
   
  }

  getCategorias() {
    this.listadoCategorias = this.juegoService.getCategorias();
  }

  async paquetes()
  {

    const modal = await this.modalCtrl.create({
      component: PaquetesVideojuegoComponent,
      backdropDismiss: true,
      componentProps: { juegoObj: this.juegoObj},
    });

    await modal.present();
  }


  async presentToast(mensaje: string) {
    const toast = await this.toastCtrl.create({
      message: mensaje,
      duration: 10000
    });
    toast.present();
  }

  salirModal()
  {
    this.modalCtrl.dismiss();
  }

  
  handleFileInput(files: FileList) {
    // console.log(files.item(0));
    this.formData = new FormData();
    this.formData.append('fileKey', files.item(0), files.item(0).name);
  }

  mandarImagen(videoJuegoId)
  {
    // console.log(pagoId);
    var request = new XMLHttpRequest();
    request.open("POST", "https://apigolden.digitalrising.com.gt/VideoJuego/upload/"+ videoJuegoId);
    request.send(this.formData);
  }

  public toggleAgregar(): void {
    this.agregarPaquete = !this.agregarPaquete;
  }

}
