export class PaqueteVideojuego {


    constructor(
        public nombre: string,
        public precio: number
    ) {}


}
