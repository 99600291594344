import { Persona } from "./persona";

export class Usuarios {


    constructor(
        public Nombre: string,
        public persona: Persona,
        public Contrasenia: string,
        public Estado: number,
        public Token: string,
        public TokenPush: string,
        public Rol: number,
        public LlaveCertificacion: string,
        public LlaveFirma: string,
        public Alias: string,
        public NombreEmisor: string,
        public CodigoEstablecimiento: string,
        public NombreComercial: string,
        public afiliacionIVA: string,
        public UsuarioInfile: string,
        public CorrelativoInfile: number,
        public Saldo: number,
        public puntosCanjeables?: number


    ) {}


}
