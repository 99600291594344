import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Pedido } from 'src/app/models/pedido';
import { PedidoService } from 'src/app/services/pedido.service';

@Component({
  selector: 'app-historial-pedido',
  templateUrl: './historial-pedido.component.html',
  styleUrls: ['./historial-pedido.component.scss'],
})
export class HistorialPedidoComponent implements OnInit {

  pedidoObj : any;
  pedidoIdObj = new Pedido(0,"","",new Date(), 0,null);

  constructor(private pedidosService: PedidoService,
    private modalCtrl: ModalController) { }

  ngOnInit() {}

  ionViewDidEnter()
  {
    console.log("LLEGO HISTORIAL PEDIDO");
    console.log(this.pedidoObj);
    //hacer consulta de get id y asignar a objeto pedido
    this.pedidosService.getPedidosId(this.pedidoObj.pedidoId).subscribe( data => 
      {
        //asignar valor a objeto local
        console.log("PEDIDO ID");
        this.pedidoIdObj = data;
        console.log(this.pedidoIdObj);

      },
      error =>
      {
      
      });

  }

  salirModal()
  {
    this.modalCtrl.dismiss();
  }

}
