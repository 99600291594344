import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController, ModalController, PopoverController, ToastController } from '@ionic/angular';
import { PaqueteVideojuego } from 'src/app/models/paquete-videojuego';
import { BoletaService } from 'src/app/services/boleta.service';
import { LoadingService } from 'src/app/services/loading.service';
import { PedidoService } from 'src/app/services/pedido.service';
import { SharingService } from 'src/app/services/sharing.service';
import { UsuarioService } from 'src/app/services/usuario.service';
import { VideoJuegoService } from 'src/app/services/video-juego.service';
import { EditarJuegoComponent } from '../editar-juego/editar-juego.component';
import { OpcionesCompraComponent } from '../opciones-compra/opciones-compra.component';
import { PaquetesVideojuegoComponent } from '../paquetes-videojuego/paquetes-videojuego.component';
import { PopVoucherComponent } from '../pop-voucher/pop-voucher.component';


@Component({
  selector: 'app-pop-opciones-paquetes',
  templateUrl: './pop-opciones-paquetes.component.html',
  styleUrls: ['./pop-opciones-paquetes.component.scss'],
})
export class PopOpcionesPaquetesComponent implements OnInit {

  juegoObj : any;
  itemPaquete: any;
  rol: number;
  listPaquetes: PaqueteVideojuego[] = [];
  tasaConversion: number = 0;
  esPublico: boolean;
  codigoRecomendacion : string;

  @ViewChild('contentPrincipal') private contentPrincipal: any;

  constructor(private router: Router,
    private alertController: AlertController,
    private popCtrl: PopoverController,
    private modalCtrl: ModalController,
    private sharingService: SharingService,
    private boletaService: BoletaService,
    private toastCtrl: ToastController,
    private loading: LoadingService,
    private usuarioService: UsuarioService,
    private juegoService: VideoJuegoService,
    private pedidoService: PedidoService) { }

  ngOnInit() {

    this.sharingService.sharingRolObservable.subscribe((_rol)=>{
      console.log("LLEGO NUEVO VALOR DE ROL OBSERVABLE");
      if(_rol != null)
      {
      this.rol = _rol;
      }
      else
      {
        //adquirir de localstorage
        this.rol = parseInt(localStorage.getItem("rol"));
      }
      console.log(this.rol);
      
    });

   
  }

  scrollAbajo()
  {
    this.contentPrincipal.scrollToBottom(400);
  }


  tasa()
  {
    //obtener tasa de conversion de backend X puntos son a Y quetzales
    this.pedidoService.getTasa().subscribe(data =>
      {
        console.log("TASA: ");
        console.log(data.tasa);
        this.tasaConversion = data.tasa; //10 puntos son 1 quetzales

      });


  }

  ionViewDidEnter()
  {
    if(this.juegoObj.jsonVideoJuego != null)
    this.listPaquetes = JSON.parse(this.juegoObj.jsonVideoJuego);
    console.log(this.juegoObj);
    this.tasa();
  }

  irMessenger()
  {
    window.open(this.juegoObj.linkMessenger);
  }

  irInstagram()
  {
    window.open(this.juegoObj.linkInstagram);
  }

  async editar()
  {

    const modal = await this.modalCtrl.create({
      component: EditarJuegoComponent,
      backdropDismiss: false,
      componentProps: { juegoObj: this.juegoObj},
    });

    await modal.present();
    await modal.onWillDismiss().then(data =>
      {
        this.popCtrl.dismiss();
      });
  }

  async eliminar()
  {
 
    const alert = await this.alertController.create({
      header: 'Eliminar Videojuego',
      message: '¿Confirmas que deseas eliminar ' + this.juegoObj.nombre + '?',
      buttons: [
          {
            text:'Cancelar',
            handler: () => {
         
            }
          }, {
            text:'Confirmo',
            handler: () => {

              console.log("VIDEOJUEGO A ELIMINAR");
            
              let paqueteDelete: PaqueteVideojuego = new PaqueteVideojuego("eliminado", 0);

              this.listPaquetes.push(paqueteDelete);
              this.juegoObj.jsonVideoJuego = JSON.stringify(this.listPaquetes);
              console.log(this.juegoObj);
              
            this.juegoService.putVideoJuego(this.juegoObj).subscribe
            (
              data =>
              {
                console.log(data);
                this.popCtrl.dismiss();
                this.presentToast('Eliminaste el videojuego');
              },
              error => {
                console.log(error);
                this.presentToast('Ocurrio un error, intenta de nuevo más tarde y si persiste por favor dirígete a nuestra area de ayuda. ');
              }
            );


          
            }
          }],
      backdropDismiss: false
    });

    await alert.present();
  }

  async paquetes()
  {
 
    const modal = await this.modalCtrl.create({
      component: PaquetesVideojuegoComponent,
      backdropDismiss: true,
      componentProps: { juegoObj: this.juegoObj},
    });

    await modal.present();
    await modal.onWillDismiss().then(data =>
      {
        this.popCtrl.dismiss();
      });
  }



  async presentToast(mensaje: string) {
    const toast = await this.toastCtrl.create({
      message: mensaje,
      duration: 10000
    });
    toast.present();
  }

  actualizarSaldo()
  {
      //OBTENER SALDO INICIAL
      this.usuarioService.getSaldoUsuario().subscribe(data =>
        {
          console.log("SALDO DE USUARIO");
          console.log(data);
          localStorage.setItem("saldo", data.saldo.toString());
          localStorage.setItem("saldoAcumulado", data.saldoAcumulado.toString());
          this.sharingService.setSaldoObservable(data.saldo);

        });
  }


  async irOpcionesCompra(itemPaqueteObj: PaqueteVideojuego ) {

    this.modalCtrl.dismiss(0);

    if(this.esPublico == true)
    {
      //direccionar a login
     // this.router.navigate(['/landing']);
      this.presentToast("Para comprar el paquete debes registrarte o iniciar sesión");
    }
    else
    {
      itemPaqueteObj.precio = this.tasaConversion * itemPaqueteObj.precio;
      this.juegoObj.codigoReferido = this.codigoRecomendacion;

      const popover = await this.popCtrl.create({
        component: OpcionesCompraComponent,
        componentProps: { juegoObj: this.juegoObj, itemPaquete: itemPaqueteObj},
        event: this.juegoObj
      });

      return await popover.present();
    }
  }

  

}
