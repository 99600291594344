import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController, ModalController, PopoverController, ToastController } from '@ionic/angular';
import { Persona } from 'src/app/models/persona';
import { Usuarios } from 'src/app/models/usuarios';
import { BoletaService } from 'src/app/services/boleta.service';
import { LoadingService } from 'src/app/services/loading.service';
import { SharingService } from 'src/app/services/sharing.service';
import { UsuarioService } from 'src/app/services/usuario.service';
import { EditarJuegoComponent } from '../editar-juego/editar-juego.component';
import { PaquetesVideojuegoComponent } from '../paquetes-videojuego/paquetes-videojuego.component';
import { PopVoucherComponent } from '../pop-voucher/pop-voucher.component';

@Component({
  selector: 'app-opciones-compra',
  templateUrl: './opciones-compra.component.html',
  styleUrls: ['./opciones-compra.component.scss'],
})
export class OpcionesCompraComponent implements OnInit {

  juegoObj : any;
  rol: number;
  itemPaquete: any;
  personaObj = new Persona("0","","","","","","","","",
  "","","");
  usuarioObj = new Usuarios("", this.personaObj, "", 0,"","",0,"","","","","",
  "","","",0,0);

  

  saldo: number = 1;
  cant: number = 1;

  constructor(private router: Router,
    private alertController: AlertController,
    private popCtrl: PopoverController,
    private modalCtrl: ModalController,
    private sharingService: SharingService,
    private boletaService: BoletaService,
    private toastCtrl: ToastController,
    private loading: LoadingService,
    private usuarioService: UsuarioService) { }

  ngOnInit() {

    this.sharingService.sharingRolObservable.subscribe((_rol)=>{
      console.log("LLEGO NUEVO VALOR DE ROL OBSERVABLE");
      if(_rol != null)
      {
      this.rol = _rol;
      }
      else
      {
        //adquirir de localstorage
        this.rol = parseInt(localStorage.getItem("rol"));
      }
      console.log(this.rol);
      
    });

    this.sharingService.sharingSaldoObservable.subscribe((_saldo)=>{
      console.log("LLEGO NUEVO VALOR DE SALDO OBSERVABLE EN OPCIONES COMPRA");
      if(_saldo != null)
      {
      this.saldo = _saldo;
      }
      else
      {
        //adquirir de localstorage
        this.saldo = parseInt(localStorage.getItem("saldo"));
      }
      console.log(this.saldo);
      
    });

  }

  irOpcionCompra(opcion)
  {
    if(opcion == 1)
    {
     //levantar popup con confirmacion de compra con puntos
     this.popCtrl.dismiss();
     this.confirmarCompraPuntos();
    }
    else if(opcion == 2)
    {
      
      
      //direccionar a comprar con qr/boleta y canjear puntos para juego
      this.popCtrl.dismiss();
      //Recalculo previo a enviar a pagina de compra con billetera
      this.juegoObj.cantidadPaquete = this.cant;
      this.itemPaquete.precio = this.itemPaquete.precio * this.cant;
      this.sharingService.sharingJuego = this.juegoObj;
      this.sharingService.sharingPaquete = this.itemPaquete;
      this.sharingService.sharingUsuario = this.usuarioObj;
      localStorage.setItem("sharingJuego", JSON.stringify(this.juegoObj));
      localStorage.setItem("sharingUsuario", JSON.stringify(this.usuarioObj));
      localStorage.setItem("sharingPaquete", JSON.stringify(this.itemPaquete));
      this.router.navigate(['/compra']);
    }
  }


  async confirmarCompraPuntos() {
    console.log("llego confirmar puntos");

    //validar si tiene puntos suficientes para comprar con puntos

    if(this.saldo >= this.itemPaquete.precio)
    {

      const alert = await this.alertController.create({
        header: 'Compra con saldo',
        message: '¿Deseas comprar ' + this.juegoObj.nombre + ' - ' + this.itemPaquete.nombre + ' por Q' + this.itemPaquete.precio + ' x ' + this.cant + '?',
        buttons: [
            {
              text:'Cancelar',
              handler: () => {
                
              }
            }, {
              text:'Comprar',
              handler: () => {

                //pagar con puntos
                this.loading.present();
                this.juegoObj.puntos = this.itemPaquete.precio * this.cant;
                this.juegoObj.paquete = this.itemPaquete.nombre;
                this.juegoObj.cantidadPaquete = this.cant;

                if(this.juegoObj.puntos != 0)
                {
                
                  this.boletaService.pagoConPuntos(this.juegoObj).subscribe( data => 
                    {
                      console.log("pago con puntos");
                      console.log(data);
                      this.actualizarSaldo();
                      this.loading.dismiss();
                      this.presentToast("Has comprado " + this.juegoObj.nombre + " - " + this.itemPaquete.nombre);
                      this.popVoucher(data.urlVoucher);
                  
                    },
                    error =>
                    {
                      this.loading.dismiss();
                    });
                }
                else
                {
                  this.loading.dismiss();
                  this.presentToast("Videojuego tiene precio Q0, por favor comunícate con nosotros para validar tu compra.");
                }
              
              }
            }],
        backdropDismiss: false
      });

      await alert.present();
    }
    else
    {
      this.presentToast("Por favor recarga tu cuenta para adquirir este videojuego");
    }

  }

  async editar()
  {
    this.popCtrl.dismiss();
    const modal = await this.modalCtrl.create({
      component: EditarJuegoComponent,
      backdropDismiss: false,
      componentProps: { juegoObj: this.juegoObj},
    });

    await modal.present();
  }

  async paquetes()
  {
    this.popCtrl.dismiss();
    const modal = await this.modalCtrl.create({
      component: PaquetesVideojuegoComponent,
      backdropDismiss: true,
      componentProps: { juegoObj: this.juegoObj},
    });

    await modal.present();
  }



  async presentToast(mensaje: string) {
    const toast = await this.toastCtrl.create({
      message: mensaje,
      duration: 2000
    });
    toast.present();
  }

  actualizarSaldo()
  {
      //OBTENER SALDO INICIAL
      this.usuarioService.getSaldoUsuario().subscribe(data =>
        {
          console.log("SALDO DE USUARIO");
          console.log(data);
          localStorage.setItem("saldo", data.saldo.toString());
          //PUNTOS ACUMULADOS
          localStorage.setItem("saldoAcumulado", data.saldoAcumulado.toString());
          this.sharingService.setSaldoObservable(data.saldo);

        });
  }

  
  async popVoucher(urlVoucher) {

    //let url = 'https://files.swigit.gt/100888704/logo.jpg';
    const popover = await this.popCtrl.create({
      component: PopVoucherComponent,
      componentProps: { src: urlVoucher},
      size: "auto",
      cssClass: 'contact-popover'
    });

    await popover.present();

    await popover.onWillDismiss().then(data =>
      {
        //direccionar a login
      });

  }

}
