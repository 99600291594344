import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Pedido } from '../models/pedido';
import { ResultadoReporte } from '../models/resultado-reporte';

@Injectable({
  providedIn: 'root'
})
export class PedidoService {

  baseUrl = 'https://apigolden.digitalrising.com.gt'; //https://apigoldenplay.digitalrising.com.gt
  constructor(private http: HttpClient) { }

  getPedidos(){

    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'usuario': localStorage.getItem("personaId")
      });

    return this.http.get<Pedido>(this.baseUrl + "/Pedido", {headers: headers});
  }

  getPedidosId(id){

    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'usuario': localStorage.getItem("personaId")
      });

    return this.http.get<Pedido>(this.baseUrl + "/Pedido/" + id, {headers: headers});
  }

  putPedido(pedidoId, estado)
  {
    
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'usuario': localStorage.getItem("personaId")
      });
    return this.http.put<Pedido>(this.baseUrl + "/Pedido/" + pedidoId + "/" + estado, pedidoId, { headers: headers });
  }

  getTasa(){

    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'usuario': localStorage.getItem("personaId")
      });

    return this.http.get<any>(this.baseUrl + "/Tasa", {headers: headers});
  }

  getRptPedidosXLSX(filtroFecha: any)
  {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'usuario': localStorage.getItem("personaId")
      });
      return this.http.post<ResultadoReporte>("https://rptapi.goldenplay.com.gt/Reporteria/rptPedidos", filtroFecha, { headers: headers });
  }


}
