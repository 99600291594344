import { Component, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-pop-promocional',
  templateUrl: './pop-promocional.component.html',
  styleUrls: ['./pop-promocional.component.scss'],
})
export class PopPromocionalComponent implements OnInit {

  constructor(   private popCtrl: PopoverController) { }

  ngOnInit() {}

  cerrar()
  {
    this.popCtrl.dismiss();
  }

}
