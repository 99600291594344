import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MenuController, Platform } from '@ionic/angular';
import { InicioPage } from 'src/app/pages/inicio/inicio.page';
import { UsuarioService } from 'src/app/services/usuario.service';
import { Observable} from 'rxjs';
import { VideoJuego } from './models/video-juego';
import { SharingService } from './services/sharing.service';
import { Persona } from './models/persona';
import { Usuarios } from './models/usuarios';


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {


  personaId: string;
  usuario: string;
  empresa: string;
  nit: string;
  anuncioActivo: boolean = false;

  user={user:'',empresa:'',tipo:0,img:''};
  usertype=0;

  juegoObj = new VideoJuego(0,"","",0,"");
  rol: number;

  personaObj = new Persona("0","","","","","","","","",
  "","","");
  usuarioObj = new Usuarios("", this.personaObj, "", 0,"","",0,"","","","","",
  "","","",0,0);


  constructor(private router: Router,
    public menuCtrl: MenuController,
    private authService:UsuarioService,
    private platform: Platform,
    private usuarioService: UsuarioService,
    private sharingService: SharingService) { }

  ngOnInit() {

    if(this.platform.is('desktop') || this.platform.is('mobileweb')){
      localStorage.setItem("isNative", "false");
    }else{
      localStorage.setItem("isNative", "true");
    }

    this.authService.refresh();
    this.authService.activeUser.subscribe((_user)=>{
      if(_user)
        this.user = _user
      else
        this.user={user:'',empresa:'',tipo:0,img:''};
      
    });


    this.sharingService.sharingRolObservable.subscribe((_rol)=>{
      console.log("LLEGO NUEVO VALOR DE ROL OBSERVABLE");
      //VALIDAR AQUI SI HAY ALGUN VALOR EN LOCALSTORAGE
      if(_rol != null)
      {
      this.rol = _rol;
      }
      else
      {
        //adquirir de localstorage
        this.rol = parseInt(localStorage.getItem("rol"));
      }
      console.log(this.rol);
      
    });
 
 
  }


  ionWillOpen()
  {
    console.log("llego menu ionDidOpen");

  }



  
  logOut()
  {

    this.authService.userLogout();
    this.user={user:'',empresa:'',tipo:0,img:'' };
    //reiniciar variables
    localStorage.setItem("sharingJuego", null);
    localStorage.setItem("sharingUsuario", null);
    localStorage.setItem("sharingPaquete", null);
    localStorage.setItem("saldo", null);
    localStorage.setItem("saldoAcumulado", null);
    localStorage.setItem("sharingPedido", null);
    localStorage.setItem("token", null);
    localStorage.setItem("personaId", null);
    localStorage.setItem("email",  null);
    localStorage.setItem("rol",  null);

    delete localStorage["sharingJuego"];
    delete localStorage["sharingUsuario"];
    delete localStorage["sharingPaquete"];
    delete localStorage["saldo"];
    delete localStorage["saldoAcumulado"];
    delete localStorage["sharingPedido"];
    delete localStorage["token"];
    delete localStorage["personaId"];
    delete localStorage["email"];
    delete localStorage["rol"];



    this.menuCtrl.toggle();
    this.router.navigate(['/inicio']);
  }

  irCompra()
  {
    this.sharingService.sharingJuego = this.juegoObj;
    this.sharingService.sharingUsuario = this.usuarioObj;
    localStorage.setItem("sharingJuego", JSON.stringify(this.juegoObj));
    localStorage.setItem("sharingUsuario", JSON.stringify(this.usuarioObj));
    this.router.navigate(['/compra']);
  }



  errorImg()
  {
    //console.log("sin imagen");
    this.user.img = "https://digitalrising.com.gt/wp-content/uploads/2020/10/sinperfil.png";  

  }


}
