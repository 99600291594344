import { Component, OnInit } from '@angular/core';
import { ActionSheetController, ModalController, PopoverController, ToastController } from '@ionic/angular';
import { PedidoService } from 'src/app/services/pedido.service';
import { HistorialPedidoComponent } from '../historial-pedido/historial-pedido.component';

@Component({
  selector: 'app-opciones-pedido',
  templateUrl: './opciones-pedido.component.html',
  styleUrls: ['./opciones-pedido.component.scss'],
})
export class OpcionesPedidoComponent implements OnInit {


  pedidoObj : any;

  constructor(   private actionSheetController: ActionSheetController,
    private popCtrl: PopoverController,
    private modalCtrl: ModalController,
    private pedidosService: PedidoService,
    private toastCtrl: ToastController) { }

  ngOnInit() {}

  async cambiarEstado()
  {
    

    const actionSheet = await this.actionSheetController.create({
      header: 'Estado de pedido',
      cssClass: 'nuevosEstados',
      buttons: [{
        text: 'Recibido',
        icon: 'enter',
        cssClass: this.pedidoObj.estado == 1 ? 'estadoActual' : '',
        handler: () => {
          this.editarPedido(1);
        }
      }, {
        text: 'Procesando',
        icon: 'reload',
        cssClass: this.pedidoObj.estado == 2 ? 'estadoActual' : '',
        handler: () => {
          this.editarPedido(2);
        }
      }, {
        text: 'Completado',
        icon: 'checkmark-circle',
        cssClass: this.pedidoObj.estado == 3 ? 'estadoActual' : '',
        handler: () => {
          this.editarPedido(3);
        }
      }, 
      {
        text: 'Cancelar',
        icon: 'close',
        role: 'cancel',
        handler: () => {
          console.log('Cancel clicked');
        }
      }]
    });
    await actionSheet.present();
  }

  async verHistorico()
  {
      this.popCtrl.dismiss();
      //levantar pop con informacion de log historico
      const modal = await this.modalCtrl.create({
        component: HistorialPedidoComponent,
        backdropDismiss: true,
        componentProps: { pedidoObj: this.pedidoObj},
      });
  
      await modal.present();
  }

  editarPedido(estado)
  {
    this.pedidosService.putPedido(this.pedidoObj.pedidoId, estado).subscribe( data => 
      {
     
        console.log("PEDIDO ACTUALIZADO");
        console.log(data);
        this.presentToast("Pedido actualizado");
        this.actionSheetController.dismiss();
        this.popCtrl.dismiss();
     

      },
      error =>
      {
        this.popCtrl.dismiss();
      });
  }

  async presentToast(mensaje: string) {
    const toast = await this.toastCtrl.create({
      message: mensaje,
      duration: 2000
    });
    toast.present();
  }

}
