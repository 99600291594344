import { Component, OnInit } from '@angular/core';

import { File } from '@ionic-native/file/ngx';
import { PhotoLibrary } from '@awesome-cordova-plugins/photo-library/ngx';
import { PopoverController, ToastController } from '@ionic/angular';



@Component({
  selector: 'app-pop-voucher',
  templateUrl: './pop-voucher.component.html',
  styleUrls: ['./pop-voucher.component.scss'],
})
export class PopVoucherComponent implements OnInit {


  src: any;
  isNative : Boolean;

  constructor(   private file: File,
   private photoLibrary: PhotoLibrary,
    private toastCtrl: ToastController,
    private popCtrl: PopoverController) { }

  ngOnInit() {
    this.photoLibrary.requestAuthorization({read:true,write:true});
    if(localStorage.getItem("isNative")=="false"){
      this.isNative=false;
    }else{
      this.isNative=true;
    }
  }

  ionViewDidEnter()
  {
   
  }


  download() {

    // let url = 'https://files.swigit.gt/100888704/logo.jpg';
    /* EJEMPLO DESCARGAR ARCHIVO USANDO FILETRANSFER (NO GUARDA EN GALERIA)
    const transfer = this.ft.create();
    let url = 'https://files.swigit.gt/100888704/logo.jpg';
    transfer.download(url, this.file.dataDirectory + 'imagenPR.jpg').then((entry) => {
      console.log('download complete: ' + entry.toURL());
      alert('download complete: ' + entry.toURL());
      this.src = entry.toURL();
      this.photoLibrary.saveImage(url, 'golden').then((data)=> {
        
      alert("guardada en galeria");
      },
      error =>
      {
        alert(JSON.stringify(error));
      });
      
    }, (error) => {
      // handle error
      alert('error: ' + JSON.stringify(error));
      console.log("download error source " + error.source);
      console.log("download error target " + error.target);
      console.log("upload error code: " + error.code);
    });
    */
 
    if(this.isNative == true)
    {

    this.photoLibrary.saveImage(this.src, 'Goldenplay').then((data)=> {
        
      alert("guardada en galeria");
      },
      error =>
      {
        alert(JSON.stringify(error));
      });


      this.presentToast("¡Gracias por tu compra! Encontrarás en tu galería tu voucher");
      this.popCtrl.dismiss();
    }
    else
    {
      this.downloadFile(this.src.toString(), this.src);
    }
   
  }

 downloadFile(url, fileName){
  console.log(url);
  const aElement = document.createElement('a');
  aElement.setAttribute('download', fileName);
  aElement.href = url;
  aElement.setAttribute('target', '_blank');
  aElement.click();
  }

  async presentToast(mensaje: string) {
    const toast = await this.toastCtrl.create({
      message: mensaje,
      duration: 8000
    });
    toast.present();
  }

  descargarWeb()
  {
    const anchor = window.document.createElement('a');
    anchor.href = this.src;
    anchor.download = this.src;
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
    window.URL.revokeObjectURL(anchor.href);
  }

  salirModal()
  {
    this.popCtrl.dismiss();
  }



}
