import { Component, OnInit, Input } from '@angular/core';
import { ItemReorderEventDetail, ModalController } from '@ionic/angular';
import { Observable } from 'rxjs';
import { CategoriaVideojuego } from 'src/app/models/categoria-videojuego';
import { VideoJuego } from 'src/app/models/video-juego';
import { VideoJuegoService } from 'src/app/services/video-juego.service';

@Component({
  selector: 'app-orden-videojuegos',
  templateUrl: './orden-videojuegos.component.html',
  styleUrls: ['./orden-videojuegos.component.scss'],
})
export class OrdenVideojuegosComponent implements OnInit {

  @Input("categoria") categoria;
  listadoCategorias: CategoriaVideojuego[];


  videojuegos: VideoJuego[];
  constructor(    private juegoService: VideoJuegoService,
    private modalCtrl: ModalController) { }

  ngOnInit() {
    console.log("Entro al componente de ordenamiento.")
    console.log(this.categoria);
    this.juegoService.getVideoJuegosxCategoria(this.categoria).subscribe(data=>
      {
        this.videojuegos = data;

      });
  }

  handleReorder(ev: CustomEvent<ItemReorderEventDetail>) {

    console.log(ev);
    console.log('Before complete', this.videojuegos);
    this.videojuegos = ev.detail.complete(this.videojuegos);

     //foreach para actualizar todos los videojuegos
     for(let i=0; i < this.videojuegos.length; i++){
      //i = indice actualizado de la lista, este setear en put
      console.log(i);
      //obtener videojuego
      console.log(this.videojuegos[i]);
      this.videojuegos[i].indiceOrdenamiento = i;
      this.juegoService.putVideoJuegoOrdenCategoria(this.videojuegos[i], this.categoria).subscribe( data => 
        {
            
        });

     }

    console.log('After complete', this.videojuegos);
  
  }

  salirModal()
  {
    this.modalCtrl.dismiss();
  }
  

}
