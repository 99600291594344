import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Storage } from '@ionic/storage';
import { Platform } from '@ionic/angular';
import { Usuarios } from '../models/usuarios';
import { Respuesta } from '../models/respuesta';



interface UsuariosData {
  // personaId: number,
  // nombre1: string,
  // nombre2: string,
  // apellido1: string,
  // apellido2: string,
  // email: string,
  // telefono: string,
  // fechaNacimiento: string,
  // nit: string,
  // rutaImagen: string
  persona: string
}

const USER = 'user';
const USERTYPE = 'tipo';


@Injectable({
  providedIn: 'root'
})
export class UsuarioService {


  baseUrl = 'https://apigolden.digitalrising.com.gt/Usuario';
  // baseUrl = 'https://jsonplaceholder.typicode.com/users';

  //private _usuarios = new BehaviorSubject<Usuarios[]>([]);
  authenticationState = new BehaviorSubject(false);
  activeUser = new BehaviorSubject({user:'',empresa:'',tipo:0,img:''});
  //userType = new BehaviorSubject(0);
  public usuario: any;

 
  constructor( private http: HttpClient, private storage: Storage, private platform: Platform) { 
    /*this.platform.ready().then(()=>{
      this.checkToken();
    });*/
  }

  /*checkToken(){
    this.storage.get(USER).then(res => {
      if(res)
        this.authenticationState.next(true);
    });
  }*/

  getUsuarios(){

    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'token': localStorage.getItem("token")
      });

  

    return this.http.get(`${this.baseUrl}`, {headers: headers});
  }

  getUsuariosArray(){

    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'token': localStorage.getItem("token")
      });

    return this.http.get<[]>(this.baseUrl, {headers: headers});
  }

  getUsuario(idusuario)
  {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'token': localStorage.getItem("token")
      });
    return this.http.get<Usuarios>(`${this.baseUrl}/${idusuario}`, {headers: headers});
     
  }

  getUsuarioId(idusuario)
  {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'token': localStorage.getItem("token")
      });
    return this.http.get<Usuarios>(`${this.baseUrl}/${idusuario}`, {headers: headers});
     
  }

  getSaldoUsuario()
  {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'usuario': localStorage.getItem("personaId")
      });
    return this.http.get<Respuesta>("https://apigolden.digitalrising.com.gt/Usuario/Saldo", {headers: headers});
     
  }


  getUsuarioLogin(usuarioLogin)
  {
    var headers = new HttpHeaders();
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');
 

    /*this.activeUser.next({user:usuarioLogin.NombreUsuario,empresa:'',tipo:0,img:''});  
    this.storage.set(USER,{user:usuarioLogin.NombreUsuario,empresa:'',tipo:0,img:''}).then(res => {
        this.authenticationState.next(true);
      });*/
    console.log("Lo que se envia a hacer login");
    console.log(usuarioLogin);
    return this.http.post('https://apigolden.digitalrising.com.gt/Usuario/Login', usuarioLogin, {headers: headers});
     
  }



  isAuthenticated(){

    //validar si tiene valor de localstorage y devolver true o false
    if(localStorage.getItem("personaId") != null)
    {
      return true;
    }
    else
    {
      return false;
    }
   
  }

  /*getUsuarioActivo(){ 
    return this.storage.get(USER).then(res => {
      console.log('back service: ' + res);
    });
  }
  getUsuarioTipo(){ 
    return this.storage.get(USERTYPE).then(res => {
      console.log('back service tipo: ' + res);
    });
  }
*/
  setUserInfo(user){
    return this.storage.set(USER,user).then(res => {
      //this.userType.next(tipo);
      this.activeUser.next(user);
      this.authenticationState.next(true);
    });
  }

  refresh(){
    this.storage.get(USER).then(res => {
      if(res){
        //console.log(JSON.stringify(res));
        this.activeUser.next(res);
        this.authenticationState.next(true);
      }else{
        this.activeUser.next({user:'',empresa:'',tipo:0,img:''});
        this.authenticationState.next(false);
      }
      
    });
    /*this.storage.get(USERTYPE).then(res => {
      console.log(JSON.stringify(res));
      this.userType.next(res);
    });*/
  }

  userLogout(){
    this.storage.remove(USER).then(res => {
      this.authenticationState.next(false);
      this.activeUser.next({user:'',empresa:'',tipo:0,img:''});
    });
  }

  postUsuario(usuario)
  {
  //  console.log(usuario);
    var headers = new HttpHeaders();
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');
    return this.http.post('https://apigolden.digitalrising.com.gt/Verificacion/CrearUsuario', usuario, { headers: headers });
  }

  postUsuarioPreRegistro(preRegistro)
  {
  //  console.log(usuario);
    var headers = new HttpHeaders();
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');
    return this.http.post('https://apigolden.digitalrising.com.gt/Verificacion/PreRegistro', preRegistro, { headers: headers });
  }

  putUsuario(usuario)
  {
    
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'token': localStorage.getItem("token")
      });
    return this.http.put('https://apigolden.digitalrising.com.gt/Usuario/ModificarUsuario', usuario, { headers: headers });
  }

  putContrasenia(recuperacionContrasenia)
  {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'token': recuperacionContrasenia.token
      });
    this.http.put('https://apigolden.digitalrising.com.gt/Usuario/NuevaContrasenia', recuperacionContrasenia, { headers: headers }).subscribe();
  }

  reestablecerContrasenia(usuario)
  {
    var headers = new HttpHeaders();
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');
    this.http.put('https://apigolden.digitalrising.com.gt/Usuario/ModificarUsuario', usuario, { headers: headers }).subscribe();
  }

  putTokenPush(usuario)
  {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'token': localStorage.getItem("token")
      });

    //  console.log("llego token push");
    //  console.log(localStorage.getItem("token"));
    //this.http.post('https://api.swigit.gt/Usuario/TokenPush', usuario, { headers: headers }).subscribe();
    this.http.put('https://apigolden.digitalrising.com.gt/Usuario/TokenPush', usuario, { headers: headers }).subscribe();

  }

  correoRecuperacion(recuperacionContrasenia)
  {
    //console.log(recuperacionContrasenia);
    var headers = new HttpHeaders();
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');
    this.http.post('https://apigolden.digitalrising.com.gt/Usuario/RecuperarContrasenia', recuperacionContrasenia, { headers: headers }).subscribe();
  }

  putSaldoUsr(usuarioId, puntos)
  {
    
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'usuario': usuarioId.toString()
      });

      console.log("url");
      console.log('https://apigolden.digitalrising.com.gt/Usuario/Saldo/' + puntos);
    return this.http.put('https://apigolden.digitalrising.com.gt/Usuario/Saldo/' + puntos, {"usuario": 0}, { headers: headers });
  }

  putSaldoAcumulado(usuarioId, puntos)
  {
    
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'usuario': usuarioId.toString()
      });

      console.log("url");
      console.log('https://apigolden.digitalrising.com.gt/Usuario/Saldo/' + puntos);
    return this.http.put('https://apigolden.digitalrising.com.gt/Usuario/SaldoCanje/' + puntos, {"usuario": 0}, { headers: headers });
  }

  putSaldoUsrResta(usuarioId, puntos)
  {
    
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'usuario': usuarioId.toString()
      });

      console.log("url");
      console.log('https://apigolden.digitalrising.com.gt/Usuario/RestarSaldo/' + puntos);
    return this.http.put('https://apigolden.digitalrising.com.gt/Usuario/RestarSaldo/' + puntos, {"usuario": 0}, { headers: headers });
  }

  putSaldoUsrResta_Saldo(usuarioId, puntos)
  {
    
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'usuario': usuarioId.toString()
      });

      console.log("url");
      console.log('https://apigolden.digitalrising.com.gt/Usuario/RestarSaldo/' + puntos);
    return this.http.put('https://apigolden.digitalrising.com.gt/Usuario/RestarSaldoSinPuntos/' + puntos, {"usuario": 0}, { headers: headers });
  }

  putSaldoSinPuntos(usuarioId, puntos)
  {
    
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'usuario': usuarioId.toString()
      });

      console.log("url");
      console.log('https://apigolden.digitalrising.com.gt/Usuario/RestarSaldo/' + puntos);
    return this.http.put('https://apigolden.digitalrising.com.gt/Usuario/SumarSaldoSinPuntos/' + puntos, {"usuario": 0}, { headers: headers });
  }

  putPuntosSinSaldo(usuarioId, puntos)
  {
    
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'usuario': usuarioId.toString()
      });

      console.log("url");
      console.log('https://apigolden.digitalrising.com.gt/Usuario/RestarSaldo/' + puntos);
    return this.http.put('https://apigolden.digitalrising.com.gt/Usuario/SumarPuntosSinSaldo/' + puntos, {"usuario": 0}, { headers: headers });
  }





}
