import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Boleta } from '../models/boleta';
import { Respuesta } from '../models/respuesta';
import { Pago } from '../models/pago';
import { VideoJuego } from '../models/video-juego';
import { ResultadoReporte } from '../models/resultado-reporte';


@Injectable({
  providedIn: 'root'
})
export class BoletaService {

  baseUrl = 'https://apigolden.digitalrising.com.gt'; //https://apigoldenplay.digitalrising.com.gt
  constructor(private http: HttpClient) { }

  getBoletas(){

    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'usuario': localStorage.getItem("personaId")
      });

    return this.http.get<any>(this.baseUrl + "/Pago", {headers: headers});
  }

  getRecomendaciones(){

    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'usuario': localStorage.getItem("personaId")
      });

    return this.http.get<any>(this.baseUrl + "/Referencia", {headers: headers});
  }

  postBoleta(pagoObj: Pago)
  {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'usuario': localStorage.getItem("personaId")
      });

    //  console.log(pagoObj);
    console.log(localStorage.getItem("personaId"));
    return this.http.post<Respuesta>(this.baseUrl + "/Pago", pagoObj, { headers: headers });
  }

  deleteBoleta(pagoId)
  {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'usuario': localStorage.getItem("personaId")
      });

    //  console.log(pagoObj);
    console.log(localStorage.getItem("personaId"));
    return this.http.delete<any>(this.baseUrl + "/Pago/" + pagoId, { headers: headers });
  }

  pagoConPuntos(videoJuego: VideoJuego)
  {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'usuario': localStorage.getItem("personaId")
      });

      console.log(videoJuego);
    console.log(localStorage.getItem("personaId"));
    return this.http.post<Respuesta>(this.baseUrl + "/Pago/PagoConPuntos", videoJuego, { headers: headers });
  }

  
  canjeBoleta(pagoObj: Pago, personaId)
  {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'usuario': personaId.toString()
      });

    //  console.log(pagoObj);
    console.log(personaId);
    return this.http.post<Respuesta>(this.baseUrl + "/Pago/Canje", pagoObj, { headers: headers });
  }

  getRptBoletasXLSX(filtroFecha: any)
  {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'usuario': localStorage.getItem("personaId")
      });
      return this.http.post<ResultadoReporte>("https://rptapi.goldenplay.com.gt/Reporteria/rptBoletas", filtroFecha, { headers: headers });
  }


}
