import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer-golden',
  templateUrl: './footer-golden.component.html',
  styleUrls: ['./footer-golden.component.scss'],
})
export class FooterGoldenComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
