import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PopOpcionesUsuariosComponent } from './pop-opciones-usuarios/pop-opciones-usuarios.component';
import { HeadPuntosComponent } from './head-puntos/head-puntos.component';
import { PopPedidosComponent } from './pop-pedidos/pop-pedidos.component';
import { QRCodeModule } from 'angularx-qrcode';
import { GeneradorQRComponent } from './generador-qr/generador-qr.component';
import { NuevoJuegoComponent } from './nuevo-juego/nuevo-juego.component';
import { EditarJuegoComponent } from './editar-juego/editar-juego.component';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { EscanerQRComponent } from './escaner-qr/escaner-qr.component';
import { NgxScannerQrcodeModule } from 'ngx-scanner-qrcode';
import { OpcionesPedidoComponent } from './opciones-pedido/opciones-pedido.component';
import { HistorialPedidoComponent } from './historial-pedido/historial-pedido.component';
import { PopPromocionalComponent } from './pop-promocional/pop-promocional.component';
import { OpcionesCompraComponent } from './opciones-compra/opciones-compra.component';
import { PopVoucherComponent } from './pop-voucher/pop-voucher.component';


import { File } from '@awesome-cordova-plugins/file';
import { PaquetesVideojuegoComponent } from './paquetes-videojuego/paquetes-videojuego.component';
import { PopOpcionesPaquetesComponent } from './pop-opciones-paquetes/pop-opciones-paquetes.component';
import { OrdenVideojuegosComponent } from './orden-videojuegos/orden-videojuegos.component';
import { FooterGoldenComponent } from './footer-golden/footer-golden.component';




@NgModule({
  declarations: [
  PopOpcionesUsuariosComponent,
HeadPuntosComponent,
PopPedidosComponent,
GeneradorQRComponent,
NuevoJuegoComponent,
EditarJuegoComponent,
EscanerQRComponent,
OpcionesPedidoComponent,
HistorialPedidoComponent,
PopPromocionalComponent,
OpcionesCompraComponent,
PopVoucherComponent,
PaquetesVideojuegoComponent,
PopOpcionesPaquetesComponent, OrdenVideojuegosComponent, FooterGoldenComponent],
  imports: [
    CommonModule,
    QRCodeModule,
    FormsModule,
    IonicModule,
    NgxScannerQrcodeModule
  ],
  exports: [
    PopOpcionesUsuariosComponent,
    HeadPuntosComponent,
    PopPedidosComponent,
    GeneradorQRComponent,
    NuevoJuegoComponent,
    EditarJuegoComponent,
    EscanerQRComponent,
    OpcionesPedidoComponent,
    HistorialPedidoComponent,
    PopPromocionalComponent,
    OpcionesCompraComponent,
    PopVoucherComponent,
    PaquetesVideojuegoComponent,
    PopOpcionesPaquetesComponent,
    OrdenVideojuegosComponent, 
    FooterGoldenComponent
    
  ]
})
export class ComponentsModule { }
